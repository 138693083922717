import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = [ 'header', 'content', 'footer' ]

  connect() {
    this.initScroll()
    this.minimizeOnIndex()
    this.invertPageOnSpecials()
  }

  enable(event) {
    document.querySelector('footer').style.filter = "invert(0%)"
    this.headerTarget.style.display = 'none'
    this.contentTarget.style.display = 'none'
    this.footerTarget.style.backgroundColor = getComputedStyle(this.footerTarget).getPropertyValue('--red-color')
    document.body.style.backgroundColor = getComputedStyle(this.footerTarget).getPropertyValue('--red-color')
    this.footerTarget.style.cursor = 'auto'
  }

  disable(event) {
    if (event.target.classList.contains('nav-controller-ignore')) {
      return null
    }

    this.headerTarget.style.display = 'flex'
    this.contentTarget.style.display = 'block'
    this.footerTarget.style.backgroundColor = getComputedStyle(this.footerTarget).getPropertyValue('--foreground-color')
    document.body.style.backgroundColor = getComputedStyle(this.footerTarget).getPropertyValue('--foreground-color')
    this.footerTarget.style.cursor = 'auto'
    this.invertPageOnSpecials()
  }

  search(event) {
    this.enable(event)
    document.querySelector('.footer__items__search input').focus()
  }

  minimizeOnIndex() {
    if (window.location.pathname === '/') {
      document.querySelector('header').classList.add('header--minimized')
      document.querySelectorAll('header > *:not(.logo)').forEach(e => {
        e.classList.add('header-item--minimized')
      })
    }
  }

  invertPageOnSpecials() {
    if (window.location.pathname === '/articles/special') {
      document.querySelector('.content').style.filter = "invert(100%)"
      document.querySelector('footer').style.filter = "invert(100%)"
      document.querySelectorAll('.content img').forEach(e => {
        e.style.filter = "invert(100%)"
      })
    }
  }

  scaleNav() {
    let currentScrollPos = window.pageYOffset
    let currentScrollDirDown = (this.prevScrollpos < currentScrollPos) ? true : false

    if (currentScrollPos < 30) {
      document.querySelector('header').classList.remove('header--scaled-down')
      document.querySelectorAll('header > *').forEach(e => {
        e.classList.remove('scaled-down')
      })
      this.prevScrollDirDown = false
    } else if (currentScrollDirDown && !this.prevScrollDirDown) {
      document.querySelector('header').classList.add('header--scaled-down')
      document.querySelectorAll('header > *').forEach(e => {
        e.classList.add('scaled-down')
      })
      document.querySelector('header').classList.remove('header--minimized')
      document.querySelectorAll('header > *:not(.logo)').forEach(e => {
        e.classList.remove('header-item--minimized')
      })

      this.prevScrollDirDown = currentScrollDirDown
    }

    this.prevScrollpos = currentScrollPos
  }

  initScroll() {
    this.prevScrollpos = window.pageYOffset
    this.prevScrollDirDown = false
  }

  onScroll() {
    window.requestAnimationFrame(this.scaleNav.bind(this))
  }
}