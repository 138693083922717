import { Controller } from 'stimulus'
import smoothscroll from 'smoothscroll-polyfill'

export default class extends Controller {
  static targets = [ 'slider', 'start', 'clone' ]

  connect() {
    smoothscroll.polyfill()
    this.recalcScroll()
  }

  autoClickToScroll() {
    window.setInterval(function() {
      this._scrollIntoView(document.elementFromPoint(screen.width * 0.97, screen.height * 0.50), 'start')
    }.bind(this), 5000)
  }

  clickToScroll(event) {
    let clickX = event.clientX
    let backArea = this.sliderTarget.clientWidth * 0.05
    let forwardArea = this.sliderTarget.clientWidth * 0.95

    if (clickX < backArea) {
      event.preventDefault()
      this._scrollIntoView(event.target, 'start')
    }

    if (clickX > forwardArea) {
      event.preventDefault()
      this._scrollIntoView(event.target, 'start')
    }

    this.onScroll()
  }

  _scrollIntoView(target, pos) {
    target.scrollIntoView({behavior: "smooth", block: pos, inline: pos})
  }

  onScroll() {
    window.requestAnimationFrame(this.updateScroll.bind(this))
  }

  onResize() {
    this.recalcScroll()
  }

  updateScroll() {
    if (this.disableScroll === false) {
      if (this.clonesWidth + this.scrollPos >= this.sliderTarget.scrollWidth) {
        // Scroll to the left when you’ve reached the far right
        this.scrollPos = 1 // Scroll 1 pixel to allow scrolling backwards.
        this.disableScroll = true
      } else if (this.scrollPos <= 0) {
        // Scroll to the right when you reach the far left.
        this.scrollPos = (this.sliderTarget.scrollWidth - this.clonesWidth)
        this.disableScroll = true
      }

      if (this.disableScroll) {
        // Disable scroll-jumping for a short time to avoid flickering.
        window.setTimeout(function () {
          this.disableScroll = false
        }.bind(this), 40)
      }
    }
  }

  recalcScroll() {
    if (this.scrollPos <= 0) {
      this.scrollPos = 1
    }
  }

  get scrollPos() {
    return (this.sliderTarget.pageXOffset || this.sliderTarget.scrollLeft) - (this.sliderTarget.clientLeft || 0)
  }

  set scrollPos(pos) {
    this.sliderTarget.scrollLeft = pos
  }

  get clonesWidth() {
    let width = 0
    let i = 0

    for (i; i < this.cloneTargets.length; i += 1) {
      width = width + this.cloneTargets[i].clientWidth
    }

    return width
  }

  get disableScroll() {
    if (this._disableScroll) {
      return true
    } else {
      return false
    }
  }

  set disableScroll(value) {
    this._disableScroll = value
  }
}