import { Controller } from 'stimulus'
import Rails from '@rails/ujs'
import * as ActiveStorage from "@rails/activestorage"
import Trix from 'trix'
import "trix/dist/trix.css"
import "../trix-editor-overrides"

export default class extends Controller {
  static targets = [ 'editor', 'textColor', 'bgColor' ]

  connect() {
    Rails.refreshCSRFTokens()
    ActiveStorage.start()

    this.setColors()

    if (this._isNew()) {
      this.enable()
    }
  }

  enable(event) {
    event && event.preventDefault()

    this._hide('.article--show')
    this._show('.article--edit')
  }

  disable(event) {
    event.preventDefault()

    if (this._isNew()) {
      this._goBack()
    } else {
      this._hide('.article--edit')
      this._show('.article--show')
    }
  }

  _hide(selector) {
    document.querySelectorAll(selector).forEach(e => {
      e.classList.add('hidden')
    })
  }

  _show(selector) {
    document.querySelectorAll(selector).forEach(e => {
      e.classList.remove('hidden')
    })
  }

  _isNew() {
    if (window.location.toString().match(/\/(new|articles)$/)) {
      return true
    } else {
      return false
    }
  }

  _goBack() {
    window.history.back()
  }

  setColors() {
    this.editorTarget.style.color = this.textColorTarget.value
    this.editorTarget.style.backgroundColor = this.bgColorTarget.value
  }
}