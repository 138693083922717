import Trix from 'trix'

window.addEventListener("trix-file-accept", function(event) {
  const acceptedTypes = [
    'image/jpeg',
    'image/png',
    'image/svg+xml',
    'image/gif',
    'audio/aac',
    'audio/mpeg',
    'video/mp4',
    'video/H264',
    'video/H265',
    'video/quicktime',
    'application/pdf'
  ]
  const maxFileSizeBytes = 25 * (1024 * 1024)
  
  if (!acceptedTypes.includes(event.file.type)) {
    event.preventDefault()
    alert(`Accepted attachments: ${acceptedTypes}`)
  }

  if (event.file.size > maxFileSizeBytes) {
    event.preventDefault()
    alert(`Maximum accepted attachment file size: ${maxFileSizeBytes/(1024 * 1024)}MB`)
  }
})

Trix.config.textAttributes.textAlignCenter = { tagName: 'div-text-align-center' }
Trix.config.textAttributes.textAlignRight = { tagName: 'div-text-align-right' }
Trix.config.textAttributes.textAlignLeft = { tagName: 'div-text-align-left' }
Trix.config.textAttributes.textAlignJustify = { tagName: 'div-text-align-justify' }

// Trix.config.textAttributes.textAlignRight = { 
//   style: { textAlign: "right", display: "block" },
//   parser: function(element) {
//     return element.style.textAlign.match(/right/)
//   },
//   inheritable: true
// }

// Trix.config.textAttributes.textAlignLeft = { 
//   style: { textAlign: "left", display: "block" },
//   parser: function(element) {
//     return element.style.textAlign.match(/left/)
//   },
//   inheritable: true
// }

// Trix.config.textAttributes.textAlignJustify = { 
//   style: { textAlign: "justify", display: "block" },
//   parser: function(element) {
//     return element.style.textAlign.match(/justify/)
//   },
//   inheritable: true
// }

window.addEventListener("trix-initialize", function(event) {  
  let buttonHTML = '<button type="button" class="trix-button" data-trix-attribute="textAlignCenter">-C-</button>'
  buttonHTML += '<button type="button" class="trix-button" data-trix-attribute="textAlignRight">--R</button>'
  buttonHTML += '<button type="button" class="trix-button" data-trix-attribute="textAlignLeft">L--</button>'
  buttonHTML += '<button type="button" class="trix-button" data-trix-attribute="textAlignJustify">JJJ</button>'

  document.querySelector(".trix-button-group--text-tools").insertAdjacentHTML("beforeend", buttonHTML)
})