import { Controller } from 'stimulus'
import smoothscroll from 'smoothscroll-polyfill'

export default class extends Controller {
  connect() {
    smoothscroll.polyfill()
  }

  scroll(event) {
    let letter = event.target.getAttribute('data-letter')
    let target = document.querySelector(`.tags__index__tag--${letter}`)
    let yOffset = document.querySelector('.tags__header').clientHeight
    let y = target.getBoundingClientRect().top + window.pageYOffset - yOffset
    
    window.scrollTo({top: y, behavior: 'smooth'})
  }
}