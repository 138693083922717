import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = [ 'content', 'link' ]

  more(event) {
    event.preventDefault()
    this.linkTarget.style.visibility = "hidden"

    let url = this.linkTarget.getAttribute('href')

    fetch(url, { headers: { 'Render-Inline': 'true' } })
      .then(response => response.text())
      .then(newItems => {
        this.linkTarget.remove()
        window.history.pushState(url, document.title, url)
        this.contentTarget.insertAdjacentHTML('beforeend', newItems)
        this.invertPageOnSpecials()
      })
  }

  invertPageOnSpecials() {
    if (window.location.pathname === '/articles/special') {
      document.querySelector('.content').style.filter = "invert(100%)"
      document.querySelector('footer').style.filter = "invert(100%)"
      document.querySelectorAll('.content img').forEach(e => {
        e.style.filter = "invert(100%)"
      })
    }
  }
}