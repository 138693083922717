import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = [ 'article' ]

  connect() {
    this.setAttachmentPreviewImgBackgroundColor()
  }

  setAttachmentPreviewImgBackgroundColor() {
    this.articleTarget.querySelectorAll('.attachment--preview img').forEach(e => {
      e.style.backgroundColor = e.alt.match(/primary\-color: (.+);/)[1]
    })
  }
}